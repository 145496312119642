import React from 'react';
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import p07borrego01Audio from "../audio/p07borrego01Audio.m4a"
import p07borrego02Audio from "../audio/p07borrego02Audio.m4a"
import p07VO from "../audio/p07VO.m4a"
import Footer from "../components/Footer"

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../images/', false, /.jpg$|.png/));

class Page07 extends React.Component {
  constructor(props){
    super(props)
    this.state = { active: false,
                   cencerro: images["p07_cencerro.png"],
                 }
  }
  playAudio = (x) => {
    const audioEl = document.getElementsByClassName("audio-element")[x]
     audioEl.play()
}
handleCencerroClick = () => {
  this.setState({ active: !this.state.active }, () => {
      if(this.state.active){
        setTimeout( () => {this.setState({cencerro: images["resetBotonTrans.png"]})}, 1000 )
      }
      else { this.setState({cencerro: images["p07_cencerro.png"]}) }
    })
}
  render() {
    return (
      <div className="main-container">
      <Link to="/Page06">
        <div className="page-turner">
          <img style={{transform: "scaleX(-1)", opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
      <Link to="/Page08">
        <div className="page-turner right-page-pos">
        <img style={{opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
        <div className="centered-container">
          <div className="backContainer004">
          <StaticQuery
            query={graphql`
              query {
                imageOne: file(relativePath: { eq: "p07_back.jpg" }) {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
      render={data => (
        <Img fluid={data.imageOne.childImageSharp.fluid} />
      )}
    />
          </div>
          <div className="p07Cuentamelo"  onClick={() => this.playAudio(2)}>
            <img className="hw" src={images["botonCuentamelo.png"]} alt="" />
          </div>
          <div className={"_idContainer091" + (this.state.active ? " active" : "")} >
            <div className="_idContainer079">
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_sombraBorreguitoL.png"]} alt="" />
            </div>
            <div className={"_idContainer080" + (this.state.active ? " active" : "")} >
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_borreguitoLLegFrontRTop.png"]} alt="" />
            </div>
            <div className="_idContainer083">
              <div className="_idContainer081">
                <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_borreguitoLLegBackRBott.png"]} alt="" />
              </div>
              <div className="_idContainer082">
                <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_borreguitoLLegBackRTop.png"]} alt="" />
              </div>
            </div>
            <div className="_idContainer084">
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_borreguitoL.png"]} alt="" />
            </div>
            <div className="_idContainer087">
              <div className="_idContainer085">
                <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_borreguitoLLegBackLBott.png"]} alt="" />
              </div>
              <div className="_idContainer086">
                <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_borreguitoLLegBackLTop.png"]} alt="" />
              </div>
            </div>
            <div className={"_idContainer090" + (this.state.active ? " active" : "")} >
              <div className={"_idContainer088" + (this.state.active ? " active" : "")} >
                <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_borreguitoLLegFrontLBott.png"]} alt="" />
              </div>
              <div className="_idContainer089">
                <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_borreguitoLLegFrontLTop.png"]} alt="" />
              </div>
            </div>
          </div>
          <div className="_idContainer092">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_borregoL.png"]} alt="" />
          </div>
          <div className={"_idContainer105" + (this.state.active ? " active" : "")} >
            <div className="_idContainer093">
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_sombraBorreguitoR.png"]} alt="" />
            </div>
            <div className={"_idContainer094" + (this.state.active ? " active" : "")} >
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_borreguitoRLegFrontRTop.png"]} alt="" />
            </div>
            <div className="_idContainer097">
              <div className="_idContainer095">
                <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_borreguitoRLegBackRBott.png"]} alt="" />
              </div>
              <div className="_idContainer096">
                <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_borreguitoRLegBackRTop.png"]} alt="" />
              </div>
            </div>
            <div className="_idContainer100">
              <div className="_idContainer098">
                <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_borreguitoRLegBackLBott.png"]} alt="" />
              </div>
              <div className="_idContainer099">
                <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_borreguitoRLegBackLTop.png"]} alt="" />
              </div>
            </div>
            <div className="_idContainer101">
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_borreguitoR.png"]} alt="" />
            </div>
            <div className={"_idContainer104" + (this.state.active ? " active" : "")} >
              <div className={"_idContainer102" + (this.state.active ? " active" : "")} >
                <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_borreguitoRLegFrontLBott.png"]} alt="" />
              </div>
              <div className="_idContainer103">
                <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_borreguitoRLegFrontLTop.png"]} alt="" />
              </div>
            </div>
          </div>
          <div className="_idContainer106">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p07_borregoR.png"]} alt="" />
          </div>
          <div className="_idContainer107 Basic-Text-Frame">
            <span>Cada oveja andaba con su pareja.</span>
          </div>
          <div className="_idContainer108" onClick={() => this.playAudio(1)}>
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4257.png"]} alt="" />
          </div>
          <div className="_idContainer109" onClick={() => this.playAudio(0)}>
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4258.png"]} alt="" />
          </div>
          <div className={"_idContainer110" + (this.state.active ? " active" : "")} onClick={this.handleCencerroClick}>
            <img className="cencerroReset _idGenObjectAttribute-1 _idGenObjectAttribute-2" src={this.state.cencerro} alt="" />
          </div>
          <audio className="audio-element">
            <source src={p07borrego01Audio}></source>
          </audio>
          <audio className="audio-element">
            <source src={p07borrego02Audio}></source>
          </audio>
          <audio className="audio-element">
            <source src={p07VO}></source>
          </audio>
        </div>
      <Footer />
      </div>
    )
  }
  }

export default Page07;
